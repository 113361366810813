import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import Container from 'components/Container/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ReactGA from 'react-ga';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useRouterContext from 'hooks/useRouterContext';

const useStyles = makeStyles(
  (theme) => ({
    mainContainer: {
      background:
        theme.palette.mode === 'light' ? (theme.palette as any).white : theme.palette.grey[900],
      marginTop: 60,
    },
    containerContent: {
      maxWidth: 1320,
      margin: '0 auto',
    },
    inputSearch: {
      width: '100%',
      fontSize: 60,
      lineHeight: '70px',
    },
    title: {
      fontSize: 202,
    },
    subtitle: {
      fontSize: 77,
      color: 'rgba(0,0,0,.3)',
      paddingTop: 70,
    },
    textField: {
      width: '100%',
    },
    containerSearch: {
      marginTop: 40,
      marginBottom: 40,
    },
    [theme.breakpoints.down('md')]: {
      inputSearch: {
        fontSize: 24,
        lineHeight: '30px',
      },
    },
  }),
  { name: 'WMNotFound' }
);

type OwnProps = {
  error?: {
    [key: string]: any;
    code: number;
  };
};

const defaultProps = {
  error: {
    code: 404,
  },
};

type Props = OwnProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'NotFound' implicitly has type 'any' because it do... Remove this comment to see the full error message
const NotFound = ({ error = {} }: Props) => {
  const routerContext = useRouterContext();
  const classes = useStyles();
  const navigate = useNavigate();

  const [state, setState] = useState({
    inputs: {
      q: '',
    },
  });

  useEffect(() => {
    const errorCode = Number(error.code);
    if (errorCode !== 404) {
      ReactGA.exception({
        description: `${JSON.stringify(errorCode)} => ${window.location}`,
        fatal: true,
      });
    }
  }, []);

  const handleInput = (event: any) => {
    const { target } = event;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(prev: { inputs: { q: string; };... Remove this comment to see the full error message
    setState((prev) => ({
      ...prev,
      inputs: { [target.name]: target.value },
    }));
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    const {
      inputs: { q },
    } = state;
    // do some sort of verification here if you need to
    navigate(`/search?q=${q}`);
  };

  if (routerContext) {
    routerContext.code = error.code || 404;
  }

  const title = error.code === 404 ? 'Not found - WebMediums' : 'Error loading - WebMediums';
  const description = error.code === 404 ? 'We couldn’t find this page.' : 'Error loading';

  return (
    // @ts-expect-error ts-migrate(2746) FIXME: This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message
    <Container size="publicationSize">
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Grid item xs={12}>
          <Grid container alignContent="center">
            <Grid item xs={12} sm={5}>
              <Typography variant="h1" className={classes.title}>
                {error.code}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h2" className={classes.subtitle}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerSearch}>
          <form action="/search" onSubmit={submitHandler}>
            <Grid container alignContent="center" alignItems="center" spacing={5}>
              <Grid item xs={12} sm={9}>
                <TextField
                  name="q"
                  variant="standard"
                  onChange={handleInput}
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.inputSearch } }}
                  placeholder="Search in WebMediums"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button type="submit" variant="outlined" size="large">
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
NotFound.defaultProps = defaultProps;
export default NotFound;
